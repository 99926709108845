import React, { useEffect, useRef, useState } from 'react'
import CreateUserStyle from './CreateUserStyle'
import Modal from 'react-modal'
import Button from '../../Button'
import DistrictData from './Regions/districts.json'
import VillageData from './Regions/villages.json'
import { get } from 'lodash'
import api, { customPhotoUploadApi } from '../../../api'
import { useSelector } from 'react-redux'
import customMainApi from '../../../api'
import { ErrorModal, SuccessModal } from '../index'
import CheckByPhoneModal from '../CheckByPhoneModal'
import { useNavigate } from 'react-router-dom'
import { message, Input, Button as AntButton } from 'antd'
import { useTranslation } from 'react-i18next'

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		// width: '70%',
		height: '90%',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: 15,
		border: 'none',
		overflowX: 'auto',
	},
	overlay: {
		background: '#00000099',
	},
}

const ScoreCreateUser = ({
	getRef = () => {},
	onConfirm = () => {},
	onClose = () => {},
	isLoading = false,
	onUpdated = () => {},
}) => {
	const { getMe } = useSelector((state) => state.main)
	const errorModalRef = useRef()
	const successModalRef = useRef()
	const navigate = useNavigate()
	const checkByPhone = useRef()
	const { t } = useTranslation()

	const [isOpenModal, setIsOpenModal] = useState(false)
	const [passport, setPassport] = useState('')

	const [phone, setPhone] = useState('')
	const [workplace, setWorkplace] = useState('')
	const [male, setMale] = useState('')
	const [userName, setUserName] = useState('')
	const [userSureName, setUserSureName] = useState('')
	const [userFathersName, setUserFathersName] = useState('')
	const [disData, setDisData] = useState([])
	const [villData, setVillData] = useState([])
	const [region, setRegion] = useState('')
	const [ageData, setAgeData] = useState('')
	const [districtData, setDistrictData] = useState([])
	const [regionsData, setRegionsData] = useState([])
	const [citiesData, setCitiesData] = useState([])

	const [district, setDistrict] = useState('')
	const [village, setVillage] = useState('')

	const [loading, setLoading] = useState(false)
	const [katm, setKatm] = useState('')
	const [mib, setMib] = useState('')
	const [nasiya, setNasiya] = useState('')
	const [score, setScore] = useState('')
	const [series, setSeries] = useState([])
	const [serie, setSerie] = useState('')
	const [comment, setComment] = useState('')
	const [comment2, setComment2] = useState('')
	const [userFields, setUserFields] = useState([])
	const [bornAdress, setBornAdress] = useState('')
	const [salary, setSalary] = useState('')
	const [imageList, setImageList] = useState([
		{ file: null, path: '', imgLoading: false },
	])

	const [addresses, setAddresses] = useState([
		{
			region: '',
			district: '',
			village: '',
			U_Street: '',
			U_HouseNumber: '',
			U_Apartment: '',
		},
	])

	const [language, setLanguage] = useState('')

	useEffect(() => {
		const ref = {
			open: () => {
				resetForm()
				setIsOpenModal(true)
			},
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [])

	const getRegion = (text) => {
		let a = Number(text.split(' - ')[1])

		let changed_data = DistrictData.filter((v) => {
			return a === Number(get(v, 'region_id', 0))
		})
		setDisData(changed_data)
		setRegion(text)
		setDistrict('')
		setVillage('')
	}

	const getDistrict = (text) => {
		let a = Number(text.split(' - ')[1])

		let changed_data = VillageData.filter((v) => {
			return a === Number(get(v, 'district_id', 0))
		})
		setVillData(changed_data)
		setDistrict(text)
		setVillage('')
	}

	const fetchDistricts = (cityCode) => {
		if (cityCode.trim()) {
			api
				.get(`U_ADDRESS_DISCRTICT?$filter=U_CityCode eq '${cityCode}'`, {
					headers: {
						Prefer: 'odata.maxpagesize=100',
					},
				})
				.then((res) => {
					const items = JSON.parse(res.data).value
					setDistrictData(items)
				})
				.catch((error) => {
					console.error('Error fetching districts in Score Create User:', error)
				})
		}
	}

	const fetchCities = (rgId) => {
		// ?$filter=U_RegionCode eq rgId
		if (rgId.trim()) {
			api
				.get(`U_ADDRESS_CITY?$filter=U_RegionCode eq '${rgId}'`, {
					headers: {
						Prefer: 'odata.maxpagesize=100',
					},
				})
				.then((res) => {
					const items = JSON.parse(res.data).value
					setCitiesData(items)
				})
		}
	}

	const fetchRegions = () => {
		api.get('U_ADDRESS_REGION').then((res) => {
			const items = JSON.parse(res.data).value
			setRegionsData(items)
		})
	}

	const getUserSeries = () => {
		api
			.post('SeriesService_GetDocumentSeries', {
				DocumentTypeParams: {
					Document: '2',
					DocumentSubType: 'C',
				},
			})
			.then((res) => {
				const items = JSON.parse(res.data).value
				const filteredItems = items.filter((item) => item.IsManual === 'tNO')
				setSeries(filteredItems)
			})
	}

	const addAddress = () => {
		setAddresses([
			...addresses,
			{
				region: '',
				district: '',
				village: '',
				U_Street: '',
				U_HouseNumber: '',
			},
		])
	}

	const resetForm = () => {
		setAddresses([
			{
				region: '',
				district: '',
				village: '',
				U_Street: '',
				U_HouseNumber: '',
				passportAdress: '',
				liveAdress: '',
				U_Apartment: '',
			},
		])
		setPassport('')
		setPhone('')
		setWorkplace('')
		setMale('')
		setUserName('')
		setUserSureName('')
		setUserFathersName('')
		setKatm('')
		setMib('')
		setNasiya('')
		setScore('')
		setSerie('')
		setBornAdress('')
		setSalary('')
	}

	const removeAddress = (index) => {
		const newAddresses = addresses.filter((_, i) => i !== index)
		setAddresses(newAddresses)
	}

	const handleAddressChange = (index, field, value) => {
		const newAddresses = addresses.map((address, i) => {
			if (i === index) {
				return { ...address, [field]: value }
			}
			return address
		})
		setAddresses(newAddresses)
	}

	const handleFileChange = (event, index) => {
		const updatedImageList = [...imageList]
		updatedImageList[index].file = event.target.files[0]
		setImageList(updatedImageList)
	}

	const uploadImg = async (index) => {
		const image = imageList[index]

		if (!image.file) {
			message.error('Фотография не выбрано')
			return
		}

		const formData = new FormData()
		formData.append('file', image.file)

		const generateRandomKey = () => {
			const characters =
				'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
			let randomKey = ''
			for (let i = 0; i < 16; i++) {
				randomKey += characters.charAt(
					Math.floor(Math.random() * characters.length),
				)
			}
			return randomKey
		}

		const randomKey = generateRandomKey()
		const title = 'YourTitle' // Replace with actual title

		formData.append('Title', title)
		formData.append('Key', randomKey)

		try {
			const updatedImageList = [...imageList]
			updatedImageList[index].imgLoading = true
			setImageList(updatedImageList)

			const res = await customPhotoUploadApi.post('assets/upload', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})

			const { key } = res.data
			updatedImageList[index].path = key
			setImageList(updatedImageList)

			message.success('Успешно загружено!')
		} catch (err) {
			message.error('Ошибка загрузки')
		} finally {
			const updatedImageList = [...imageList]
			updatedImageList[index].imgLoading = false
			setImageList(updatedImageList)
		}
	}

	const viewImage = async (index) => {
		const image = imageList[index]

		try {
			const res = await customPhotoUploadApi.get(
				`assets/download/${image.path}`,
				{
					responseType: 'blob',
				},
			)

			const url = window.URL.createObjectURL(
				new Blob([res.data], {
					type: 'image/png',
				}),
			)

			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', 'BP.png')
			document.body.appendChild(link)
			link.click()

			document.body.removeChild(link)
			window.URL.revokeObjectURL(url)
		} catch (error) {
			message.error('Ошибка загрузки файла')
		}
	}

	const addNewRow = () => {
		setImageList([...imageList, { file: null, path: '', imgLoading: false }])
	}
	const deleteRow = (index) => {
		setImageList(imageList.filter((_, i) => i !== index))
	}
	const createUserF = () => {
		setLoading(true)
		const photoId = imageList
			.map((image) => image.path)
			.filter(Boolean)
			.join(',')
		const trimmedSureName = userSureName.trim()
		const trimmedName = userName.trim()
		const trimmedFathersName = userFathersName.trim()
		api
			.post(`BusinessPartners`, {
				SalesPersonCode: getMe.SalesPersonCode || -1,
				U_workplace: workplace,
				Series: 76, //CardCode
				CardName: `${trimmedSureName} ${trimmedName} ${trimmedFathersName}`, //UserName, userSurname, userFathersName,
				CardType: 'cCustomer', // By default
				GroupCode: 100, // By default
				U_Telephone: `${phone}`,
				Currency: '##', // Bydefault
				U_Gender: male, // Erkak Ayol
				U_PS: passport, //Pasport
				U_Score: Number(score.split(' ')[0]),
				U_KATM: katm, //Tavsirlab bergan yangi narsam
				U_MIB: mib, //Tavsirlab bergan yangi narsam
				U_Nasiya: nasiya, //Tavsirlab bergan yangi narsam
				Valid: 'tYES',
				U_Salary: salary,
				U_Created: get(getMe, 'EmployeeID', ''),
				U_Respawn: bornAdress,
				U_Language: language,
				FreeText: comment,
				U_Comment: comment2,
				U_PhotoId: photoId,
				U_age_data: ageData,
				BPAddresses: addresses.map((address, idx) => ({
					AddressName: idx + 1, // Auto-generate something here if needed
					U_CityCode: address.village.split(' - ')[1], // Village
					U_DistrictCode: address.district.split(' - ')[1], // District
					U_RegionCode: address.region.split(' - ')[1], // Region
					BuildingFloorRoom: address.U_HouseNumber,
					StreetNo: address.U_Street,
					U_Apartment: address.U_Apartment,
				})),
			})
			.then(() => {
				onConfirm()
				onUpdated()
				resetForm()
				// successModalRef.current?.open('Клиент успешно создан!')
				setIsOpenModal(false)
			})
			.catch((err) => {
				errorModalRef.current?.open(JSON.parse(err.response.data).error.message)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const userFieldsFn = () => {
		customMainApi
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq 'OCRD'",
				},
				headers: {
					Prefer: 'odata.maxpagesize=100',
				},
			})
			.then((res) => {
				setUserFields(JSON.parse(res.data).value)
			})
	}

	useEffect(() => {
		userFieldsFn()
		getUserSeries()
		// fetchDistricts()
		fetchRegions()
		// fetchCities()
	}, [])

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => setIsOpenModal(false)}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<CreateUserStyle>
				<div className="card">
					<p className="title">{t('createUser')}</p>
					<div className="flex flex-col gap-5">
						<div className="flex items-center flex-col sm:flex-row gap-3">
							<input
								type="text"
								placeholder={t('lastName')}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full input1'
								}
								value={userSureName}
								onChange={(v) => setUserSureName(v.target.value)}
							/>
							<input
								type="text"
								placeholder={t('name')}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full input1'
								}
								value={userName}
								onChange={(v) => setUserName(v.target.value)}
							/>
							<input
								type="text"
								placeholder={t('fatherName')}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full input1'
								}
								value={userFathersName}
								onChange={(v) => setUserFathersName(v.target.value)}
							/>
						</div>
						<div>
							{addresses.map((address, index) => (
								<div className="flex flex-col gap-5 mt-4" key={index}>
									<hr className="w-full border-1 font-extrabold border-black" />

									{index === 0 ? (
										<b>{t('addressReg')}</b>
									) : (
										<b>{t('addressLive')}</b>
									)}
									<div className="flex items-center flex-col sm:flex-row gap-3">
										<input
											type="text"
											placeholder={t('Region')}
											list="region"
											value={address.region}
											onChange={(v) => {
												getRegion(v.target.value)
												fetchCities(
													v.target.value.split(' - ')[1]
														? v.target.value.split(' - ')[1]
														: ' ',
												)
												handleAddressChange(index, 'region', v.target.value)
											}}
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full input1'
											}
										/>
										<datalist id="region">
											{regionsData.map((v, i) => (
												<option key={i} value={`${v.Name} - ${v.Code}`} />
											))}
										</datalist>

										<input
											type="text"
											placeholder={t('village')}
											list="village"
											value={address.village}
											onChange={(v) => {
												setVillage(v.target.value)
												fetchDistricts(
													v.target.value.split(' - ')[1]
														? v.target.value.split(' - ')[1]
														: ' ',
												)
												handleAddressChange(index, 'village', v.target.value)
											}}
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full input1'
											}
										/>
										<datalist id="village">
											{citiesData.map((v, i) => (
												<option key={i} value={`${v.Name} - ${v.Code}`} />
											))}
										</datalist>
										<input
											type="text"
											placeholder={t('district')}
											list="district"
											value={address.district}
											onChange={(v) => {
												getDistrict(v.target.value)
												handleAddressChange(index, 'district', v.target.value)
											}}
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full input1'
											}
										/>
										<datalist id="district">
											{districtData.map((v, i) => (
												<option key={i} value={`${v.Name} - ${v.Code}`} />
											))}
										</datalist>
									</div>
									<div className="flex items-center flex-col sm:flex-row gap-3">
										<input
											type="text"
											placeholder={t('streetName')}
											value={address.U_Street}
											onChange={(v) =>
												handleAddressChange(index, 'U_Street', v.target.value)
											}
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full input1'
											}
										/>
										<input
											type="text"
											placeholder={t('buildingRoom')}
											value={address.U_HouseNumber}
											onChange={(v) =>
												handleAddressChange(
													index,
													'U_HouseNumber',
													v.target.value,
												)
											}
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full input1'
											}
										/>
										<input
											type="text"
											placeholder={t('apartmentNum')}
											value={address.U_Apartment}
											onChange={(v) =>
												handleAddressChange(
													index,
													'U_Apartment',
													v.target.value,
												)
											}
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full input1'
											}
										/>
									</div>

									{index !== 0 ? (
										<AntButton
											onClick={() => removeAddress(index)}
											className="mt-2 bg-red-500  h-10 text-white p-2 rounded-md"
										>
											{t('removeAddress')}
										</AntButton>
									) : (
										''
									)}
								</div>
							))}
							<AntButton
								onClick={addAddress}
								className="mt-4 bg-blue-500 h-10 text-white p-2 rounded-md"
							>
								{t('addAddress')}
							</AntButton>
						</div>
						<div className="flex flex-col sm:flex-row gap-3 items-center">
							<input
								type="text"
								placeholder={t('passport/pinfl')}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full halfInput h-10'
								}
								value={passport}
								onChange={(v) => setPassport(v.target.value)}
							/>
							<textarea
								type="text"
								placeholder={t('phone')}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full halfInput'
								}
								value={phone}
								onChange={(v) => setPhone(v.target.value)}
							/>
						</div>
						<div className="flex items-center flex-col sm:flex-row gap-3">
							<input
								type="text"
								placeholder={t('bornAddress')}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full halfInput '
								}
								value={bornAdress}
								onChange={(v) => setBornAdress(v.target.value)}
							/>
							<input
								type="date"
								placeholder={t('bornDate')}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full halfInput '
								}
								value={ageData}
								onChange={(v) => {
									// if (v.target.value.length <= 10) {
									setAgeData(v.target.value)
									// }
								}}
							/>
						</div>
						<div className="flex items-center flex-col sm:flex-row gap-3">
							{/* <div className="flex items-start "> */}

							<select
								placeholder="Статус"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md flex-1 halfInput'
								}
								value={score}
								onChange={(v) => setScore(v.target.value)}
							>
								<option value="" disabled>
									{'Статус'}
								</option>
								{userFields
									?.find((item) => item.Name === 'Score')
									?.ValidValuesMD.map((value) => (
										<option value={value.Value}>{value.Description}</option>
									))}
							</select>

							<select
								placeholder="Насия"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md flex-1 halfInput'
								}
								value={nasiya}
								onChange={(v) => setNasiya(v.target.value)}
							>
								<option value="" disabled>
									{'Насия'}
								</option>
								{userFields
									?.find((item) => item.Name === 'Nasiya')
									?.ValidValuesMD.map((value) => (
										<option value={value.Value}>{value.Description}</option>
									))}
							</select>
						</div>
						<div className="flex items-center flex-col sm:flex-row gap-3">
							<select
								placeholder="Katm"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md flex-1 halfInput'
								}
								value={katm}
								onChange={(v) => setKatm(v.target.value)}
							>
								<option value="" disabled>
									{'KATM'}
								</option>
								{userFields
									?.find((item) => item.Name === 'KATM')
									?.ValidValuesMD.map((value) => (
										<option value={value.Value}>{value.Description}</option>
									))}
							</select>

							<select
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md flex-1 halfInput'
								}
								value={mib}
								onChange={(v) => setMib(v.target.value)}
							>
								<option value="" disabled>
									{'MIB'}
								</option>
								{userFields
									?.find((item) => item.Name === 'MIB')
									?.ValidValuesMD.map((value) => (
										<option value={value.Value}>{value.Description}</option>
									))}
							</select>
						</div>
						<div className="flex items-center flex-col sm:flex-row gap-3">
							<select
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full halfInput'
								}
								value={language}
								onChange={(e) => setLanguage(e.target.value)}
							>
								<option value="" disabled>
									{t('smsSendLan')}
								</option>

								{userFields
									?.find((item) => item.Name === 'Language')
									?.ValidValuesMD.map((value) => (
										<option value={value.Value}>{value.Description}</option>
									))}
							</select>
							<input
								type="text"
								placeholder={t('comment')}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full halfInput'
								}
								value={comment}
								onChange={(v) => setComment(v.target.value)}
							/>
						</div>

						<div className="flex items-center flex-col sm:flex-row gap-3">
							<input
								type="text"
								placeholder={t('salary')}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full halfInput'
								}
								value={salary}
								onChange={(v) => setSalary(v.target.value)}
							/>
							<input
								type="text"
								placeholder={t('workplace')}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md halfInput'
								}
								value={workplace}
								onChange={(v) => setWorkplace(v.target.value)}
							/>
						</div>
						<div className="flex items-center flex-col sm:flex-row gap-3 mt-5">
							<textarea
								placeholder={`${t('comment')}`}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
								}
								value={comment2}
								onChange={(v) => setComment2(v.target.value)}
							/>
						</div>
					</div>
					<div className="flex gap-4  justify-center my-4 ml-5">
						<div className="flex gap-1">
							<input
								type="radio"
								id="male"
								name="fav_language"
								value="Male"
								onChange={(v) => setMale(v.target.value)}
							/>

							<label htmlFor="male">{t('male')}</label>
						</div>
						<div className="flex gap-1">
							<input
								type="radio"
								id="female"
								name="fav_language"
								value="Female"
								onChange={(v) => setMale(v.target.value)}
							/>
							<label htmlFor="female">{t('female')}</label>
						</div>
					</div>

					<div>
						{imageList.map((image, index) => (
							<div
								key={index}
								className="flex flex-col w-full items-start gap-3 mt-5 mb-10"
							>
								<div>
									<p className="font-bold">
										{t('photo')}-{index + 1}
									</p>
								</div>
								<div className="flex flex-col sm:flex-row items-center gap-3">
									<Input
										type="file"
										onChange={(e) => handleFileChange(e, index)}
										className="w-full"
									/>
									<AntButton
										onClick={() => uploadImg(index)}
										loading={image.imgLoading}
										// disabled={!toWarehouse}
										className="h-10 w-full sm:w-[100px] rounded-lg bg-[#0A4D68] text-white"
									>
										{t('upload')}
									</AntButton>
									{image.path && (
										<AntButton
											onClick={() => viewImage(index)}
											className="h-10 w-full sm:w-[100px] rounded-lg bg-[#0A4D68] text-white"
										>
											{t('download')}
										</AntButton>
									)}
									{index !== 0 && (
										<AntButton
											onClick={() => deleteRow(index)}
											className="h-[40px] w-full sm:w-[100px] rounded-lg p-2 bg-red-500 text-white"
										>
											{t('delete')}
										</AntButton>
									)}

									{index === imageList.length - 1 && (
										<AntButton
											onClick={addNewRow}
											// disabled={!toWarehouse}
											className="h-10 w-full sm:w-[100px] rounded-lg bg-[#0A4D68] text-white"
										>
											{t('add2')}
										</AntButton>
									)}
								</div>
							</div>
						))}
					</div>
					<div className="flex items-center justify-between flex-col sm:flex-row gap-5 w-full">
						<AntButton
							className="bg-red-500 text-white py-2 px-4 rounded-md w-full sm:w-auto h-10"
							onClick={() => {
								onClose()
								setIsOpenModal(false)
							}}
						>
							{t('no')}
						</AntButton>
						<AntButton
							className="bg-blue-900 text-white  rounded-md w-full sm:w-auto h-10"
							onClick={() => {
								checkByPhone.current?.open(phone)
							}}
							disabled={phone === ''}
						>
							{t('check')}
						</AntButton>
						<AntButton
							loading={loading}
							className="bg-blue-900 text-white  rounded-md w-full sm:w-auto h-10"
							disabled={
								userName.length <= 0 ||
								userSureName.length <= 0 ||
								userFathersName.length <= 0 ||
								passport.length <= 0 ||
								phone.length <= 0 ||
								male.length <= 0 ||
								ageData.length <= 0 ||
								addresses.some(
									(address) =>
										address.region.length <= 0 ||
										address.village.length <= 0 ||
										address.U_Street.length <= 0 ||
										address.U_HouseNumber.length <= 0,
								)
							}
							onClick={createUserF}
						>
							{t('yes')}
						</AntButton>
					</div>
				</div>
			</CreateUserStyle>
			<SuccessModal
				getRef={(r) => {
					successModalRef.current = r
				}}
				onConfirm={() => {
					navigate('/scoring-clients')
				}}
			/>
			<ErrorModal getRef={(r) => (errorModalRef.current = r)} />
			<CheckByPhoneModal
				getRef={(r) => (checkByPhone.current = r)}
				phones={phone}
			/>
		</Modal>
	)
}

export default ScoreCreateUser
